/** @jsx jsx */
import { jsx } from "theme-ui";
import Content from "@src/templates/content";
import ImageTextBlock from "@src/components/ImageTextBlock";
import SectionHeadingBlock from "@src/components/SectionHeadingBlock";
import Testimonial from "@src/components/Testimonial";
import Container from "@src/components/Container";
import CTABanner from "@src/components/CTABanner";
import InternalLink from "@src/components/InternalLink";
import { Link } from "gatsby";
export default ({ location }) => (
  <Content pageTitle="Comics Tutoring" location={location}>
    <div>
      <Container>
        <div>
          <ImageTextBlock
            alt="Bernard sitting on table in front of sketches with hands up"
            src="/images/bernard-tutor.jpg"
          />
          <ImageTextBlock
            body={
              <div>
                <h2>One-on-one lessons for all ages</h2>

                <p>
                Do you need some help getting started making your own comics? Do you know a child who is keen to make comics and needs some advice, encouragement or guidance? 
                </p>

                <p>
                Bernard Caleo has years of experience in leading kids, teens and adults through writing and drawing exercises to develop their comics making skills. These sessions can be delivered via zoom or other online platforms.
                </p>
                <p>
                Bernard holds a current Victorian Working with Children card.
                </p>
              </div>
            }
          />
        </div>
      </Container>

      <Testimonial
        text="Bernard teaches me how to draw. We create really funny comic strips. I always look
        forward to my sessions with Bernard as he is a super funny dude with crazy hair."
        author="Matilda, age 12"
      />

      <CTABanner
        lines={[
          <div>
            <Link to="/contact">Contact Bernard</Link> for more information and
            to book a comics tutoring session
          </div>,
        ]}
      />
      <Container>
        <InternalLink text="Comics mentoring" url="/comics-mentoring" />
      </Container>
    </div>
  </Content>
);
